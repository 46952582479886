



















































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AccountingLoans } from '@/model/AccountingLoans'
import { DigInputText } from '@digithia/input/text'
import moment from 'moment'
import dayjs from 'dayjs'
import toaster from '@/store/toaster'

@Component({})
export default class LoansTable extends Vue {
  @Prop() loans!: AccountingLoans[]
  @Prop() title!: number
  @Prop() financialAccountingId!: number
  @Prop() capital!: number
  @Prop() year!: number
  @Prop() locked!: boolean

  cap = 0

  isReadonly(loan: AccountingLoans) {
    return this.locked || (loan.datePreBilan && !this.isAdmin)
  }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  setDateValidator() {
    document.querySelectorAll('.input-date-year').forEach(el => {
      ;(el as DigInputText).validator = (value: string) => {
        return moment(value).year() === this.year
      }
      ;(el as DigInputText).errors.validator = `La date ne correspond pas à l'année en cours`
    })
  }

  created() {
    this.setDateValidator()
  }

  updated() {
    if (!this.cap) this.cap = this.capital

    this.setDateValidator()
  }

  toHumanDate(date: string) {
    return dayjs(date).format('DD/MM/YYYY')
  }

  notSaved: number[] = []
  lastDeleted: AccountingLoans | null = null

  saveAll() {
    for (const loan of this.loans) {
      if (this.notSaved.some(id => id === loan.id)) {
        this.edit(loan)
      }
    }
  }

  goToISuite() {
    this.$router.push({ name: 'ISuite' })
  }

  update(field: keyof AccountingLoans, value: any, loan: AccountingLoans) {
    loan[field] = value as never
    this.changed(loan.id)
    this.autoCalcAmount(loan.id)
  }

  changed(id: number) {
    if (!this.notSaved.some(i => i === id)) this.notSaved.push(id)
  }

  autoCalcAmount(id: number) {
    const loan = this.loans.find(i => i.id === id)
    if (loan && loan.capital && loan.interest && loan.insurance) {
      loan.amount = +(loan.capital + loan.interest + loan.insurance).toFixed(2)
    }
  }

  reset(id: number) {
    this.notSaved = this.notSaved.filter(i => i !== id)
  }

  edit(loan: AccountingLoans) {
    const loan1 = +(loan.amount || 0).toFixed(2)
    const loan2 = +(
      (loan.capital || 0) +
      (loan.interest || 0) +
      (loan.insurance || 0)
    ).toFixed(2)

    if (loan1 !== loan2) {
      return this.$store.dispatch('toaster/toast', {
        message: `Le montant de l'échéance ne correspond pas avec le détail de l'échéancier`,
        type: 'warning',
      })
    }
    this.$store.dispatch('editLoan', loan).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.reset(loan.id)
    })
  }

  editCapital(capital: number) {
    let cap
    if (isNaN(+capital)) {
      cap = +('' + capital).replace(/,/g, '.')
    } else {
      cap = +capital
    }
    this.$store
      .dispatch('editCapital', { id: this.financialAccountingId, capital: cap })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }

  remove(loan: AccountingLoans) {
    this.$store.dispatch('deleteLoan', loan.id).then(json => {
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.lastDeleted = loan
      }
    })
  }

  restore(loan: AccountingLoans | null) {
    if (!loan) return

    this.$store
      .dispatch('addLoan', {
        financialAccountingId: this.financialAccountingId,
        ...loan,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.lastDeleted = null
        }
      })
  }

  add() {
    this.$store
      .dispatch('addLoan', {
        financialAccountingId: this.financialAccountingId,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }

  duplicate(loan: AccountingLoans) {
    this.$store
      .dispatch('addLoan', {
        financialAccountingId: this.financialAccountingId,
        ...loan,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }
}
