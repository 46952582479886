



































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { AccountingCautions } from '@/model/AccountingCautions'
import CautionRow from './CautionRow.vue'

@Component({ components: { CautionRow } })
export default class CautionsTable extends Vue {
  @Prop() cautions!: AccountingCautions[]
  @Prop() title!: number
  @Prop() projectId!: number
  @Prop() tva!: boolean
  @Prop() locked!: boolean
  @Prop() year!: number

  notSaved: number[] = []
  lastDeleted: AccountingCautions | null = null

  @Emit('refresh') refresh() {
    return true
  }

  get totalEncaisse(): number {
    return +this.cautions
      .reduce((total, c) => +(c.cautionEncaissee || 0) + total, 0)
      .toFixed(2)
  }
  get totalRestitue(): number {
    return +this.cautions
      .reduce((total, c) => +(c.cautionRestituee || 0) + total, 0)
      .toFixed(2)
  }
  get totalSolde(): number {
    return +this.cautions
      .reduce((total, c) => +(c.solde || 0) + total, 0)
      .toFixed(2)
  }

  saveAll() {
    for (const caution of this.cautions) {
      if (this.notSaved.some(id => id === caution.id)) {
        this.edit(caution)
      }
    }
  }

  update(
    field: keyof AccountingCautions,
    value: any,
    caution: AccountingCautions,
  ) {
    caution[field] = value as never
    this.changed(caution.id)
    // this.computeSolde(caution.id)
  }

  changed(id: number) {
    if (this.notSaved.some(i => i === id)) return
    this.notSaved.push(id)
  }

  // computeSolde(id: number) {
  //   const caution = this.cautions.find(c => c.id === id)
  //   if (!caution) return
  //   const solde =
  //     (caution.cautionEncaissee || 0) - (caution.cautionRestituee || 0)
  //   caution.solde = +solde.toFixed(2)
  // }

  computeSolde(caution: AccountingCautions) {
    const solde =
      (caution.cautionEncaissee || 0) - (caution.cautionRestituee || 0)

    return +solde.toFixed(2)
  }

  reset(id: number) {
    this.notSaved = this.notSaved.filter(i => i !== id)
  }

  edit(caution: AccountingCautions) {
    caution.solde = this.computeSolde(caution)

    this.$store.dispatch('editCaution', caution).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.reset(caution.id)
    })
  }

  remove(id: number) {
    this.$store.dispatch('deleteCaution', id).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.refresh()
      if (json.success) {
        const lastCaution = this.cautions.find(c => c.id === id)
        if (lastCaution) {
          this.lastDeleted = lastCaution
        }
      }
    })
  }

  restore(caution: AccountingCautions | null) {
    if (!caution) return
    this.$store
      .dispatch('addCaution', {
        projectId: this.projectId,
        ...caution,
      })
      .then(json => {
        this.refresh()
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          this.lastDeleted = null
        }
      })
  }

  add() {
    this.$store
      .dispatch('addCaution', {
        projectId: this.projectId,
      })
      .then(json => {
        this.refresh()
        this.$store.dispatch('toaster/toast', json)
      })
  }

  duplicate(id: number) {
    const caution = this.cautions.find(c => c.id === id)
    this.$store
      .dispatch('addCaution', {
        projectId: this.projectId,
        ...caution,
      })
      .then(json => {
        this.refresh()
        this.$store.dispatch('toaster/toast', json)
      })
  }
}
