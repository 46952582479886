





















































































































































































import { Component, Prop, Vue, Ref, Emit } from 'vue-property-decorator'
import {
  AccountingExpenses,
  CATEGORIES_CHARGES,
} from '@/model/AccountingExpenses'
import ExpenseRow from './ExpenseRow.vue'
import moment from 'moment'

@Component({ components: { ExpenseRow } })
export default class ExpensesTable extends Vue {
  @Prop() financialAccountingId!: number
  @Prop() expenses!: AccountingExpenses[]
  @Prop() tva!: boolean
  @Prop() locked!: boolean
  @Prop() year!: boolean

  @Emit('refresh') refresh() {
    return true
  }

  @Ref('expenses-1') expenses1: any
  @Ref('expenses-2') expenses2: any
  @Ref('expenses-3') expenses3: any
  @Ref('expenses-4') expenses4: any
  @Ref('expenses-5') expenses5: any
  @Ref('expenses-6') expenses6: any
  @Ref('expenses-7') expenses7: any
  @Ref('expenses-8') expenses8: any
  @Ref('expenses-10') expenses9: any
  @Ref('expenses-10') expenses10: any
  @Ref('expenses-11') expenses11: any
  @Ref('expenses-12') expenses12: any

  CATEGORIES_CHARGES = CATEGORIES_CHARGES

  saveAll() {
    this.expenses1.saveAll()
    this.expenses2.saveAll()
    this.expenses3.saveAll()
    this.expenses4.saveAll()
    this.expenses5.saveAll()
    this.expenses6.saveAll()
    this.expenses7.saveAll()
    this.expenses8.saveAll()
    this.expenses9.saveAll()
    this.expenses10.saveAll()
    this.expenses11.saveAll()
    this.expenses12.saveAll()
  }
}
