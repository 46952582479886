









































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AccountingCautions } from '@/model/AccountingCautions'

@Component({})
export default class CautionsRow extends Vue {
  @Prop() caution!: AccountingCautions
  @Prop() locked!: boolean

  noChange: boolean = true
  cautionForm: AccountingCautions = JSON.parse(JSON.stringify({}))

  mounted() {
    this.cautionForm = JSON.parse(JSON.stringify(this.caution))
  }

  update(field: keyof AccountingCautions, value: any) {
    this.cautionForm[field] = value as never
    this.noChange = false
  }

  edit() {
    this.$emit('edit', this.cautionForm)
    this.noChange = true
  }

  remove() {
    this.$emit('remove', this.caution.id)
  }

  duplicate() {
    this.$emit('duplicate', this.caution.id)
  }
}
