













































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { AccountingExpenses } from '@/model/AccountingExpenses'
import { DigInputNumber, DigInputText } from '@digithia/input'
import moment from 'moment'
import dayjs from 'dayjs'

@Component({})
export default class ExpensesRow extends Vue {
  @Prop() expenses!: AccountingExpenses[]
  @Prop() title!: number
  @Prop() categorie!: number
  @Prop() financialAccountingId!: number
  @Prop() tva!: boolean
  @Prop() locked!: boolean
  @Prop() year!: number

  @Emit('refresh') refresh() {
    return true
  }

  notSaved: number[] = []
  lastDeleted: AccountingExpenses | null = null

  toHumanDate(date: string) {
    return dayjs(date).format('DD/MM/YYYY')
  }

  created() {
    this.setDateValidator()
  }

  updated() {
    this.setDateValidator()
  }

  setDateValidator() {
    document.querySelectorAll('.input-date-year').forEach(el => {
      ;(el as DigInputText).validator = (value: string) => {
        return moment(value).year() === this.year
      }
      ;(el as DigInputText).errors.validator = `La date ne correspond pas à l'année en cours`
    })
  }

  saveAll() {
    for (const expense of this.expenses) {
      if (this.notSaved.some(id => id === expense.id)) {
        this.edit(expense)
      }
    }
  }

  goToISuite() {
    this.$router.push({ name: 'ISuite' })
  }

  isReadonly(expense: AccountingExpenses) {
    return this.locked || (expense.datePreBilan && !this.isAdmin)
  }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  update(
    field: keyof AccountingExpenses,
    value: any,
    expense: AccountingExpenses,
  ) {
    expense[field] = value as never
    this.changed(expense.id)
  }

  changed(id: number) {
    if (this.notSaved.some(i => i === id)) return
    this.notSaved.push(id)
  }

  reset(id: number) {
    this.notSaved = this.notSaved.filter(i => i !== id)
  }

  calcTVA(id: number) {
    const index = this.expenses.findIndex(e => e.id === id)
    if (index >= 0) {
      if (this.tva) {
        this.expenses[index].ttc = +(
          (this.expenses[index].ht as number) / 0.8
        ).toFixed(2)
        this.expenses[index].tva = +(
          (this.expenses[index].ttc as number) * 0.2
        ).toFixed(2)
      } else {
        this.expenses[index].ht = +(
          (this.expenses[index].ttc as number) * 0.8
        ).toFixed(2)
        this.expenses[index].tva = +(
          (this.expenses[index].ttc as number) * 0.2
        ).toFixed(2)
      }
    }
    this.changed(id)
  }

  edit(expense: AccountingExpenses) {
    if (this.tva) {
      const ttc = +(expense.ttc || 0).toFixed(2)
      const htPlusTva = +((expense.ht || 0) + +(expense.tva || 0)).toFixed(2)

      if (ttc !== htPlusTva) {
        return this.$store.dispatch('toaster/toast', {
          message: `Les charges TTC ne correspondent pas aux charges HT + TVA`,
          type: 'warning',
        })
      }
    }

    this.$store.dispatch('editExpense', expense).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.reset(expense.id)
    })
  }

  remove(expense: AccountingExpenses) {
    this.$store.dispatch('deleteExpense', expense.id).then(json => {
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.refresh()
        this.lastDeleted = expense
      }
    })
  }

  restore(expense: AccountingExpenses | null) {
    if (!expense) return
    this.$store
      .dispatch('addExpense', {
        financialAccountingId: this.financialAccountingId,
        categorie: this.categorie,
        ...expense,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.refresh()
        if (json.success) {
          this.lastDeleted = null
        }
      })
  }

  add() {
    this.$store
      .dispatch('addExpense', {
        financialAccountingId: this.financialAccountingId,
        categorie: this.categorie,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.refresh()
      })
  }

  duplicate(expense: AccountingExpenses) {
    this.$store
      .dispatch('addExpense', {
        financialAccountingId: this.financialAccountingId,
        categorie: this.categorie,
        ...expense,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }
}
