





































































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { AccountingIncomes } from '@/model/AccountingIncomes'
import { DigInputText } from '@digithia/input'
import moment from 'moment'
import dayjs from 'dayjs'

@Component({})
export default class IncomesTable extends Vue {
  @Prop() incomes!: AccountingIncomes[]
  @Prop() title!: number
  @Prop() financialAccountingId!: number
  @Prop() tva!: boolean
  @Prop() locked!: boolean
  @Prop() year!: number

  @Emit('refresh') refresh() {
    return true
  }

  notSaved: number[] = []
  lastDeleted: AccountingIncomes | null = null

  isReadonly(income: AccountingIncomes) {
    return this.locked || (income.datePreBilan && !this.isAdmin)
  }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  toHumanDate(date: string) {
    return dayjs(date).format('DD/MM/YYYY')
  }

  created() {
    this.setDateValidator()
  }

  updated() {
    this.setDateValidator()
  }

  setDateValidator() {
    document.querySelectorAll('.input-date-year').forEach(el => {
      ;(el as DigInputText).validator = (value: string) => {
        return moment(value).year() === this.year
      }
      ;(el as DigInputText).errors.validator = `La date ne correspond pas à l'année en cours`
    })
  }

  saveAll() {
    for (const income of this.incomes) {
      if (this.notSaved.some(id => id === income.id)) {
        this.edit(income)
      }
    }
  }

  update(
    field: keyof AccountingIncomes,
    value: any,
    income: AccountingIncomes,
  ) {
    income[field] = value as never
    this.changed(income.id)
  }

  changed(id: number) {
    if (this.notSaved.some(i => i === id)) return
    this.notSaved.push(id)
  }

  reset(id: number) {
    this.notSaved = this.notSaved.filter(i => i !== id)
  }

  edit(income: AccountingIncomes) {
    if (this.tva) {
      const loyerNet = +(income.loyerNet || 0).toFixed(2)
      const loyerTVAPlusBrut = +(
        (income.loyerTVA || 0) + (income.loyerBrut || 0)
      ).toFixed(2)

      if (loyerNet !== loyerTVAPlusBrut) {
        return this.$store.dispatch('toaster/toast', {
          message: `Le loyer net ne correspond pas au loyer brut + TVA`,
          type: 'warning',
        })
      }

      const chargesNet = +(income.chargesLocNet || 0).toFixed(2)
      const chargesTVAPlusBrut = +(
        (income.chargesLocTVA || 0) + (income.chargesLocBrut || 0)
      ).toFixed(2)

      if (chargesNet !== chargesTVAPlusBrut) {
        return this.$store.dispatch('toaster/toast', {
          message: `Les charges nettes ne correspondent pas aux charges brutes + TVA`,
          type: 'warning',
        })
      }
    }

    this.$store.dispatch('editIncome', income).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.reset(income.id)
    })
  }

  remove(income: AccountingIncomes) {
    this.$store.dispatch('deleteIncome', income.id).then(json => {
      this.$store.dispatch('toaster/toast', json)
      this.refresh()
      if (json.success) {
        this.lastDeleted = income
      }
    })
  }

  restore(income: AccountingIncomes | null) {
    if (!income) return
    this.$store
      .dispatch('addIncome', {
        financialAccountingId: this.financialAccountingId,
        ...income,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.refresh()
        if (json.success) {
          this.lastDeleted = null
        }
      })
  }

  add() {
    this.$store
      .dispatch('addIncome', {
        financialAccountingId: this.financialAccountingId,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.refresh()
      })
  }

  duplicate(income: AccountingIncomes) {
    this.$store
      .dispatch('addIncome', {
        financialAccountingId: this.financialAccountingId,
        ...income,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        this.refresh()
      })
  }
}
