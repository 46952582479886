

































































































































































































































































import { Component, Prop, Ref, Vue, Emit } from 'vue-property-decorator'
import IncomesTable from '../incomes/IncomesTable.vue'
import ExpensesTable from '../expenses/ExpensesTable.vue'
import LoansTable from '../loans/LoansTable.vue'
import CautionsTable from '../cautions/CautionsTable.vue'
import {
  AccountingFinancial,
  ACCOUNTING_STATES,
} from '@/model/AccountingFinancial'
import * as FileSaver from 'file-saver'
import { AccountingExpenses } from '@/model/AccountingExpenses'
import { AccountingIncomes } from '@/model/AccountingIncomes'
import { AccountingLoans } from '@/model/AccountingLoans'
import { Tab } from '../../../base/BaseTab.vue'
import { User } from '@/model/User'
import { AccountingCautions } from '@/model/AccountingCautions'
import Utils from '../../../../utils/Utils'
import dayjs from 'dayjs'
import moment from 'moment'

@Component({
  components: {
    IncomesTable,
    ExpensesTable,
    LoansTable,
    CautionsTable,
  },
})
export default class extends Vue {
  @Prop() year!: number
  @Prop() projectId!: number
  @Prop() tva!: boolean

  @Prop() sortedExpenses!: AccountingExpenses[]
  @Prop() sortedIncomes!: AccountingIncomes[]
  @Prop() sortedLoans!: AccountingLoans[]
  @Prop() sortedCautions!: AccountingCautions[]

  @Ref('incomes') readonly incomesEl!: any
  @Ref('loans') readonly loansEl!: any
  @Ref('expenses') readonly expensesEl!: any
  @Ref('cautions') readonly cautionsEl!: any

  @Emit('sort') sort() {
    return true
  }
  @Emit('refresh') refresh() {
    return true
  }

  toHumanDate(date: string) {
    return dayjs(date).format('DD/MM/YYYY')
  }

  tabs: Tab[] = [
    { id: 1, label: `Revenus` },
    { id: 2, label: `Emprunts` },
    { id: 3, label: `Charges` },
    { id: 4, label: `Cautions` },
  ]

  displayLockData = false
  comment = ''
  preBilanDate = ''
  Utils = Utils

  saveAll() {
    this.incomesEl.saveAll()
    this.expensesEl.saveAll()
    this.loansEl.saveAll()
    this.cautionsEl.saveAll()
  }

  get loans(): AccountingLoans[] {
    return this.$store.getters.loans
  }
  get expenses(): AccountingExpenses[] {
    return this.$store.getters.expenses
  }
  get incomes(): AccountingIncomes[] {
    return this.$store.getters.incomes
  }

  get totalIncomesTTC(): number {
    return +this.incomes
      .reduce(
        (total, i) => +(i.loyerNet || 0) + +(i.chargesLocNet || 0) + total,
        0,
      )
      .toFixed(2)
  }
  get totalExpensesTTC(): number {
    return +this.expenses
      .reduce((total, e) => +(e.ttc || 0) + total, 0)
      .toFixed(2)
  }
  get totalDiffTTC(): number {
    return +(this.totalIncomesTTC - this.totalExpensesTTC).toFixed(2)
  }

  get totalIncomesHT(): number {
    return +this.incomes
      .reduce(
        (total, i) => +(i.loyerBrut || 0) + +(i.chargesLocBrut || 0) + total,
        0,
      )
      .toFixed(2)
  }

  get totalExpensesHT(): number {
    return +this.expenses
      .reduce((total, e) => +(e.ht || 0) + total, 0)
      .toFixed(2)
  }

  get totalDiffHT(): number {
    return +(this.totalIncomesHT - this.totalExpensesHT).toFixed(2)
  }

  get accounting(): AccountingFinancial {
    return this.$store.getters.accounting
  }

  // get cautions(): AccountingCautions[] {
  //   return this.$store.getters.cautions.filter(
  //     (caution: AccountingCautions) => {
  //       const yearDeb = Utils.UTCToYear(caution.dateDebutLocation)
  //       if (yearDeb && +yearDeb > this.year) {
  //         return false
  //       }
  //       const dateFin = Utils.UTCToYear(caution.dateFinLocation)
  //       if (dateFin && +dateFin < this.year) {
  //         return false
  //       }
  //       return true
  //     },
  //   )
  // }

  get isAdmin(): boolean {
    return this.$store.getters.isAdmin
  }

  get locked(): boolean {
    return this.accounting.state === ACCOUNTING_STATES.LOCKED
  }

  get user(): User {
    return this.$store.getters['user']
  }

  updateAccountingField(field: keyof AccountingFinancial, value: any) {
    this.$store
      .dispatch('updateAccountingField', {
        id: this.accounting.id,
        field,
        value,
      })
      .then(json => {
        if (!json.success) {
          this.$store.dispatch('toaster/toast', json)
        }
      })
  }

  generateAccounting() {
    this.$store
      .dispatch('generateAccountingReport', this.accounting.id)
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          const today = new Date()
          const filename = `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}_${this.user.username}_Écriture comptable_${
            this.accounting.year
          }`
          FileSaver.saveAs(json.data, `${filename}.xlsx`)
        }
      })
  }

  addPreAccounting(date: string) {
    this.$store
      .dispatch('addPreAccounting', { id: this.accounting.id, date: date })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          const today = new Date()
          const filename = `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}_${this.user.username}_Écriture comptable_${
            this.accounting.year
          }`
          FileSaver.saveAs(json.data.rapport, `${filename}.xlsx`)
        }
      })
  }

  downloadPreAccounting(date: string) {
    this.$store
      .dispatch('downloadPreAccounting', { id: this.accounting.id, date: date })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
        if (json.success) {
          const today = new Date()
          const filename = `${today.getFullYear()}-${today.getMonth() +
            1}-${today.getDate()}_${this.user.username}_Écriture comptable_${
            this.accounting.year
          }`
          FileSaver.saveAs(json.data.rapport, `${filename}.xlsx`)
        }
      })
  }

  lockAccounting() {
    this.$store
      .dispatch('lockAccounting', {
        id: this.accounting.id,
        comment: this.comment,
      })
      .then(json => {
        this.$store.dispatch('toaster/toast', json)
      })
  }

  unlockAccounting() {
    this.$store.dispatch('unlockAccounting', this.accounting.id).then(json => {
      this.$store.dispatch('toaster/toast', json)
    })
  }
}
